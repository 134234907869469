<template>
  <div>
    <vs-popup class="popup90" :fullscreen="false" title="Boleto(s)" :active.sync="popupShowBoleto">
      <div id="boleto-loading" class="vs-con-loading__container">
        <embed :src="boletoUrl" alt="Boleto" width="100%" height="600" type="application/pdf" pluginspage="http://www.adobe.com/products/acrobat/readstep2.html">

        <div class="vx-row">
          <vs-divider></vs-divider>
          <div class="vx-col w-full mt-0">
            <div class="mt-2 flex flex-wrap items-center justify">
              <vs-button :href="remessaUrl" class="w-full">!! Clique aqui para baixar o arquivo de remessa !!</vs-button>
            </div>
          </div>
        </div>

      </div>
    </vs-popup>

    <vs-popup class="popup90" :fullscreen="false" title="Cadastrar Receitas" :active.sync="localShow">

      <dialog-sig style="z-index: 9999999999;" :show="popupServiceQty" title="QUANTIDADE" icon="warning"
        @action="changeServiceQty"
        @cancel="closeChangeServiceQty"
        actionButtonText="Continuar" actionButtonColor="warning">
          <div class="vx-row">
            <div class="vx-col w-full mt-0">
              <vs-input type="number" min="1" class="w-full" label="Quantidade" v-model="serviceQty" />
            </div>
          </div>
      </dialog-sig>

      <permission-password style="z-index: 9999999999;" :show="popupPermissionPassword" title="ALERTA DE BAIXA RETROATIVA" icon="warning"
        actionButtonText="Prosseguir"
        @action="storeCredit(true)"
        @cancel="popupPermissionPassword = false">
      </permission-password>

      <div class="vx-row">
        <div class="vx-col md:w-1/4 w-full mt-0">
          <label class="vs-input--label">Aluno</label> <br>
          <strong>{{student.name}}</strong>
        </div>
        <!-- Pacotes agora chamamos de serviços e serviços do pacote são ítens -->
        <div class="vx-col md:w-1/4 w-full mt-0">
          <label class="vs-input--label">Serviços</label>
          <v-select v-model="credit.servicePackages" @input="changeCreditSubTotal" @option:deselected="serviceDeselected" @option:selected="newServiceSelected"
            data-vv-as="Serviços" data-vv-scope="credit" v-validate.initial="'required'" name="services"
            :options="servicePackages" multiple placeholder="Selecione" :dir="$vs.rtl ? 'rtl' : 'ltr'">
            <template #no-options="{}">
              Nenhum resultado encontrado.
            </template>
          </v-select>
          <span class="text-danger text-sm" v-show="errors.has('credit.services')">{{ errors.first('credit.services') }}</span>
        </div>
        <div class="vx-col md:w-1/6 w-full mt-0">
          <vs-input class="w-full" label="Valor" ref="subTotal" v-model="credit.sub_total" v-currency="currencyConfig" />
        </div>
        <div class="vx-col md:w-1/6 w-full mt-0">
          <label class="vs-input--label">Desconto {{ credit.discount_type }}</label>
          <vx-input-group prependClasses="border border-solid border-grey border-r-0">
            <template slot="append">
              <div class="append-text btn-addon">
                <vs-button @click="toggleDiscountType">{{ credit.discount_type }}</vs-button>
              </div>
            </template>
            <vs-input key="1__" v-if="credit.discount_type === '%'" class="w-full" type="number" v-model="credit.discount" />
            <vs-input key="2___" v-else class="w-full" ref="discount" v-model="credit.discount" v-currency="currencyConfig" />
          </vx-input-group>
        </div>
        <div class="vx-col md:w-1/6 w-full mt-0">
          <label class="vs-input--label">Total</label>
          <h1 class="flex flex-wrap justify-center">
            <sup class="text-lg">R$</sup>
            <span>{{credit.total.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }).replace('R$', '')}}</span>
          </h1>
        </div>
      </div>
      <div class="vx-row">
        <div class="vx-col md:w-1/5 w-full mt-2">
          <label class="vs-input--label">Nº de Parcelas</label>
          <vs-input-number min="1" class="mt-2" v-model="credit.parcelsNumber" @input="changeParcelsNumber"
            data-vv-as="Parcelas" data-vv-scope="credit" v-validate="'required|min_value:1'" name="parcelsNumber" />
          <span class="text-danger text-sm" v-show="errors.has('credit.parcelsNumber')">{{ errors.first('credit.parcelsNumber') }}</span>
        </div>
        <div class="vx-col md:w-1/5 w-full mt-2">
          <vs-input class="w-full mt-0" label="Valor 1ª Parcela" ref="firstParcelValue"
            :disabled="credit.parcelsNumber < 2"
            v-model="credit.firstParcelValue" v-currency="currencyConfig" />
          <span class="text-danger text-sm" v-if="errorfirstParcelValue">Este valor é maior que o total.</span>
        </div>
        <div class="vx-col md:w-1/5 w-full mt-2">
          <vs-input type="date" class="w-full" label="1º Vencimento" v-model="credit.expirationDateFirstParcel"
            data-vv-as="1º Vencimento" data-vv-scope="credit" v-validate.initial="'required'" name="expirationDateFirstParcel" />
          <span class="text-danger text-sm" v-show="errors.has('credit.expirationDateFirstParcel')">Este campo é obrigatório.</span>
          <span class="text-danger text-sm" v-if="!isSameOrAfterToday(credit.expirationDateFirstParcel)">A primeira parcela já está vencida.</span>
        </div>
        <div class="vx-col md:w-1/5 w-full mt-2">
          <label class="vs-input--label">Periodicidade</label>
          <v-select v-model="credit.frequency" :reduce="option => option.value" :clearable="false" :options="[{label: 'Mensal', value: 'monthly'}, {label: 'Quinzenal', value: 'biweekly'}, {label: 'Semanal', value: 'weekly'}]" placeholder="Selecione" :dir="$vs.rtl ? 'rtl' : 'ltr'"></v-select>
        </div>
        <div class="vx-col md:w-1/5 w-full mt-2">
          <label class="vs-input--label">Aplicar Juros</label>
          <div class="flex flex-wrap items-center justify-center">
          <vs-switch class="w-1/2 mt-2" icon-pack="feather" vs-icon-on="icon-check-circle" vs-icon-off="icon-slash" v-model="credit.applyInterest">
            <span slot="on">SIM</span> <span slot="off">NÃO</span>
          </vs-switch>
          </div>
        </div>
      </div>
      <div class="vx-row">
        <div class="vx-col md:w-1/5 w-full mt-2">
          <label class="vs-input--label">Tipo de Pagamento</label>
          <v-select class="vue_select_drop_size_180" @input="filterAccounts" v-model="credit.type_payment_id" :reduce="option => option.value" :clearable="false"
            data-vv-as="Tipo de Pagamento" data-vv-scope="credit" v-validate.initial="'required'" name="type_payment_id"
            :options="typePayments" placeholder="Selecione" :dir="$vs.rtl ? 'rtl' : 'ltr'">
            <template #no-options="{}">
              Desculpe, nenhum resultado encontrado.
            </template>
          </v-select>
          <span class="text-danger text-sm" v-show="errors.has('credit.type_payment_id')">Este campo é obrigatório.</span>
        </div>
        <div class="vx-col md:w-1/5 w-full mt-2">
          <label class="vs-input--label">Conta</label>
          <v-select class="vue_select_drop_size_180" :disabled="!credit.type_payment_id" v-model="credit.account" :reduce="option => option.value" :clearable="false"
            data-vv-as="Conta" data-vv-scope="credit" v-validate.initial="'required'" name="account"
            :options="accountsFilteredOptions" placeholder="Selecione" :dir="$vs.rtl ? 'rtl' : 'ltr'">
            <template #no-options="{}">
              Desculpe, nenhum resultado encontrado.
            </template>
          </v-select>
          <span class="text-danger text-sm" v-show="errors.has('credit.account')">Este campo é obrigatório.</span>
        </div>
        <div class="vx-col md:w-1/5 w-full mt-2">
          <label class="vs-input--label">Plano de contas</label>
          <v-select class="vue_select_drop_size_180" v-model="credit.account_plan" :reduce="option => option.value" :options="accountPlans" placeholder="Selecione" :dir="$vs.rtl ? 'rtl' : 'ltr'">
            <template #no-options="{}">
              Desculpe, nenhum resultado encontrado.
            </template>
          </v-select>
        </div>
        <div class="vx-col md:w-2/5 w-full mt-2">
          <vs-input class="w-full" label="Descrição" v-model="credit.observation" />
        </div>
      </div>

      <div class="vx-row">
        <div class="vx-col w-full mt-2">
          <div class="mt-0 flex flex-wrap items-center justify-end">
            <vs-button @click="generateParcels" :disabled="disableGenerateParcels" class="w-1/5" type="border" color="warning" icon-pack="feather" icon="icon-dollar-sign">Gerar Parcelas</vs-button>
          </div>
        </div>
      </div>

      <vs-divider position="left">
        <feather-icon icon="DollarSignIcon" class="mr-0" svgClasses="w-4 h-4" /><span class="mb-4">Parcelas</span>
      </vs-divider>

      <!-- PARCELAS -->
      <vs-table v-if="credit.transactions.length" :data="credit.transactions" hoverFlat style="overflow: -webkit-paged-y">
        <template slot="thead">
          <vs-th>#</vs-th>
          <vs-th>Valor</vs-th>
          <vs-th>Vencimento</vs-th>
          <vs-th>Forma Pagamento</vs-th>
          <vs-th>Conta</vs-th>
          <vs-th>Situação</vs-th>
          <vs-th>Pago Em</vs-th>
          <!-- <vs-th>Plano de Contas</vs-th> -->
        </template>
        <template slot-scope="{data}">
          <vs-tr :key="i" v-for="(parcel, i) in data" style="opacity: 1 !important">
            <vs-td> {{ i + 1 }} </vs-td>
            <vs-td>
                <currency-input class="w-32 vs-inputx vs-input--input normal hasValue"
                  @input="parcel.situation ? parcel.value_pay = parcel.value_parcel : parcel.value_pay = 0;"
                  v-model="parcel.value_parcel"
                  :currency="{prefix: 'R$ '}"
                  :valueAsInteger="false"
                  :distractionFree="false"
                  :precision="2"
                  :autoDecimalMode="true"
                  :valueRange="{ min: 0 }"
                  :allowNegative="false" />
            </vs-td>
            <vs-td>
              <vs-input type="date" class="w-48" v-model="parcel.expiration_date"
                data-vv-as="Vencimento" data-vv-scope="credit" v-validate.initial="{ required: true }" :name="'expiration_date'+i" />
              <span class="text-danger text-sm" v-show="errors.has('credit.expiration_date'+i)">Campo obrigatório</span>
            </vs-td>
            <vs-td>
              <v-select class="vue_select_drop_size_100 w-48" :clearable="false" @input="filterAccountsParcels(i)" v-model="parcel.type_payment_id" :reduce="option => option.value" :options="typePayments" placeholder="Selecione" :dir="$vs.rtl ? 'rtl' : 'ltr'">
                <template #no-options="{}">
                  Nenhum resultado encontrado.
                </template>
              </v-select>
            </vs-td>
            <vs-td>
              <v-select class="vue_select_drop_size_100 w-48" :clearable="false" :disabled="!parcel.type_payment_id" v-model="parcel.account"
                :reduce="option => option.value" :options="parcel.accountsFilteredOptions" placeholder="Selecione" :dir="$vs.rtl ? 'rtl' : 'ltr'"
                data-vv-as="Conta" data-vv-scope="credit" v-validate.initial="{ required: !parcel.account }" :name="'account'+i">
                <template #no-options="{}">
                  Nenhum resultado encontrado.
                </template>
              </v-select>
              <span class="text-danger text-sm" v-show="errors.has('credit.account'+i)">Campo obrigatório</span>
            </vs-td>
            <vs-td>
              <div class="flex flex-wrap items-center justify-center">
                <vs-switch class="w-28 mt-2" icon-pack="feather" vs-icon-on="icon-check-circle" vs-icon-off="icon-slash"
                  @input="parcel.situation ? parcel.value_pay = parcel.value_parcel : parcel.value_pay = 0;
                  parcel.situation ? parcel.payday = today : parcel.payday = '';
                  parcel.situation ? '' : parcel.futureDate = false;
                  checkPayday(parcel)" v-model="parcel.situation">
                  <span slot="off">ABERTA</span> <span slot="on">QUITADA</span>
                </vs-switch>
              </div>
            </vs-td>
            <vs-td>
              <vs-input type="date" class="w-48" :disabled="!parcel.situation" v-model="parcel.payday" @change="checkPayday(parcel)"
              data-vv-as="Pago em" data-vv-scope="credit" v-validate.initial="{ required: parcel.situation }" :name="'payday'+i" />
              <span class="text-danger text-sm" v-show="errors.has('credit.payday'+i)">Campo obrigatório</span>
              <span class="text-danger text-sm" v-show="parcel.futureDate">Data futura não permitida</span>
            </vs-td>
            <!-- <vs-td>
              <v-select class="vue_select_drop_size_100 w-48" :clearable="false" v-model="parcel.account_plan" :reduce="option => option.value" :options="accountPlans" placeholder="Selecione" :dir="$vs.rtl ? 'rtl' : 'ltr'">
                <template #no-options="{}">
                  Nenhum resultado encontrado.
                </template>
              </v-select>
            </vs-td> -->
          </vs-tr>

          <vs-tr style="opacity: 1 !important">
            <vs-td colspan="8">
              <vs-divider></vs-divider>
              <div class="vx-col w-full">
                <div class="mt-2 flex flex-wrap items-center">
                  <vs-button class="mr-4 mb-2" color="secondary" @click="localShow = false">Cancelar</vs-button>
                  <vs-button class="mr-auto mb-2" @click="storeCredit()" :disabled="!validateCredit || disableGenerateParcels || disableSave">Salvar</vs-button>
                </div>
              </div>
            </vs-td>
          </vs-tr>
        </template>
      </vs-table>

      <vs-alert v-if="!credit.transactions.length" class="mt-4 mb-4" :active="true" color="warning" icon-pack="feather" icon="icon-info" style="height: auto">
        <span>Por favor preencha o formulário e gere as parcelas para poder cadastrar!</span>
      </vs-alert>

    </vs-popup>

  </div>
</template>

<script>
// Store Module
import moduleTypePayment from '@/store/type-payment/moduleTypePayment.js'
import moduleAccount from '@/store/account/moduleAccount.js'
import moduleServicePackage from '@/store/service-package/moduleServicePackage.js'
import moduleAccountPlan from '@/store/account-plan/moduleAccountPlan.js'
import moduleTransaction from '@/store/transaction/moduleTransaction.js'

import moment from 'moment'
import { setValue, getValue, CurrencyDirective, CurrencyInput } from 'vue-currency-input'
const dialogSig = () => import(/* webpackChunkName: "dialogSig" */ '@/components/dialog/dialogSig.vue')
const vSelect = () => import(/* webpackChunkName: "vSelect" */ 'vue-select')
const permissionPassword = () => import(/* webpackChunkName: "permissionPassword" */ '@/components/permission-password/permissionPassword.vue')

//AJUSTE DE VENCIMENTOS 30 DIAS EM MESES COM MENOS DE 31 DIAS
Date.isLeapYear = function (year) {
  return (((year % 4 === 0) && (year % 100 !== 0)) || (year % 400 === 0))
}
Date.getDaysInMonth = function (year, month) {
  return [31, (Date.isLeapYear(year) ? 29 : 28), 31, 30, 31, 30, 31, 31, 30, 31, 30, 31][month]
}
Date.prototype.isLeapYear = function () {
  return Date.isLeapYear(this.getFullYear())
}
Date.prototype.getDaysInMonth = function () {
  return Date.getDaysInMonth(this.getFullYear(), this.getMonth())
}
Date.prototype.addMonths = function (value) {
  const n = this.getDate()
  this.setDate(1)
  this.setMonth(this.getMonth() + value)
  this.setDate(Math.min(n, this.getDaysInMonth()))
  // return `${this.getFullYear()}-0${this.getMonth() + 1}-${this.getDate()}`
  return this
}

export default {
  components: {
    dialogSig,
    vSelect,
    CurrencyInput,
    permissionPassword
  },
  props: {
    show: {
      type: Boolean, default: false
    },
    student: {
      type: Object
    }
  },
  computed: {
    today () {
      const [DD, MM, YYYY] = new Date().toLocaleDateString('pt-br').split('/')
      return `${YYYY}-${MM}-${DD}`
    },
    tomorrow () {
      const tomorrow = new Date()
      tomorrow.setDate(new Date().getDate() + 1)
      const [DD, MM, YYYY] = new Date(tomorrow).toLocaleDateString('pt-br').split('/')
      return `${YYYY}-${MM}-${DD}`
    },
    validateCredit () {
      return !this.errors.any('credit')
    },
    firstValue () { //controlo no watch para não permitir valor maior que o total
      return this.credit.firstParcelValue
    },
    discount () {
      return this.credit.discount
    },
    subTotal () {
      return this.credit.sub_total
    },
    firstParcelValue () {
      return this.credit.transactions[0] && this.credit.transactions[0].value_parcel
    },
    servicePackages () {
      return JSON.parse(JSON.stringify(this.$store.getters['servicePackage/forSelect']))
    },
    typePayments () {
      return this.$store.getters['typePayment/forSelect']
    },
    accountPlans () {
      return this.$store.getters['accountPlan/forSelectFilterReceipt']
    },
    accounts () {
      return this.$store.getters['account/forSelect']
    }
  },

  watch: {
    show (val) {
      this.localShow = val
    },
    localShow (val) {
      if (!val) {
        this.$emit('cancel')
        this.resetModal() //reseta o form ao fechar a modal
      } else {
        this.$store.dispatch('typePayment/fetchAll').then(() => { }).catch(err => { console.error(err) })
        this.$store.dispatch('account/fetchAll').then(() => { }).catch(err => { console.error(err) })
        this.$store.dispatch('servicePackage/fetchAll').then(() => { }).catch(err => { console.error(err) })
        this.$store.dispatch('accountPlan/fetchAll').then(() => { }).catch(err => { console.error(err) })
        this.credit.expirationDateFirstParcel = this.today

        // Já vem preenchido conforme serviços desejados
        const servicePreSelected = this.servicePackages.find(sp => sp.value === this.student.intended_services)
        this.credit.servicePackages = servicePreSelected ? [servicePreSelected] : []
        if (servicePreSelected) {
          if (this.credit.servicePackages.length) {
            this.credit.account_plan = this.credit.servicePackages[0].data.account_plan_id
          } else {
            this.credit.account_plan = null
          }
          const value = this.credit.servicePackages.reduce((a, v) => a + (v.currencyValue || 0), 0).toString()
          setValue(this.$refs.subTotal, value)
          this.recalculateTotal()
        }
      }
    },
    discount () {
      this.recalculateTotal()
    },
    subTotal () {
      this.recalculateTotal()
    },
    firstParcelValue (newValue) {
      this.recalculateParcelsValue(newValue)
    },
    firstValue () {
      const value = getValue(this.$refs.firstParcelValue)
      this.credit.transactions = []
      if (value > this.credit.total) {
        this.errorfirstParcelValue = true
      } else {
        this.errorfirstParcelValue = false
      }
    }
  },
  data () {
    return {
      localShow: false,
      popupPermissionPassword: false,
      popupShowBoleto: false,
      boletoUrl: '',
      remessaUrl: '',

      popupServiceQty: false,
      serviceQty: 1,

      disableSave: false,
      disableGenerateParcels: false,
      errorfirstParcelValue: false,
      currencyConfig:  {
        currency: {prefix: 'R$ '},
        valueAsInteger: false,
        distractionFree: false,
        precision: 2,
        autoDecimalMode: true,
        valueRange: { min: 0 },
        allowNegative: false
      },
      accountsFilteredOptions: [],
      credit: {
        student_id: this.student.id,
        account: null,
        type_payment_id: null,
        account_plan: null,
        servicePackages: [],
        parcelsNumber: 1,
        discount_type: '%',
        discount: 0,
        total: 0,
        sub_total: 0,
        firstParcelValue: 0,
        expirationDateFirstParcel: '',
        frequency: 'monthly',
        applyInterest: true,
        observation: null,

        transactions: []
      }
    }
  },

  directives: { currency: CurrencyDirective },

  methods: {
    //EVITA BAIXA COM DATA FUTURA
    checkPayday (parcel) {
      if (moment(parcel.payday).year() > 1000) {
        const difDays = moment(parcel.payday).diff(moment(this.today), 'days')
        if (difDays > 0) {
          parcel.futureDate = true
          this.disableSave = true
        } else {
          parcel.futureDate = false
          //checa se há alguma parcela com pagamento futuro
          const index = this.credit.transactions.findIndex(e => e.futureDate)
          if (index === -1) this.disableSave = false
        }
      } else {
        parcel.futureDate = false
        //checa se há alguma parcela com pagamento futuro
        const index = this.credit.transactions.findIndex(e => e.futureDate)
        if (index === -1) this.disableSave = false
      }
    },
    cancel () {
      this.$emit('cancel')
    },
    resetModal () {
      this.credit.student_id = this.student.id
      this.credit.account = null
      this.credit.type_payment_id = null
      this.credit.account_plan = null
      this.credit.servicePackages = []
      this.credit.parcelsNumber = 1
      this.credit.discount_type = '%'
      this.credit.discount = 0
      this.credit.total = 0
      setValue(this.$refs.subTotal, 0)
      setValue(this.$refs.firstParcelValue, 0)
      this.credit.expirationDateFirstParcel = ''
      this.credit.frequency = 'monthly'
      this.credit.applyInterest = true
      this.credit.observation = null
      this.credit.transactions = []
    },
    toggleDiscountType () {
      this.credit.discount = 0
      this.credit.discount_type = this.credit.discount_type === '%' ? '$' : '%'
    },
    isSameOrAfterToday (e) {
      if (moment(e).isValid()) return moment(e).isSameOrAfter(moment().format('YYYY-MM-DD'))
      return true
    },
    newServiceSelected (val) {
      //LAST IN ARRAY IS THE SELECTED
      const lastServiceSelected = val[this.credit.servicePackages.length - 1]
      if (lastServiceSelected.data.type_service && lastServiceSelected.data.type_service.toUpperCase() === 'AULA AVULSA') {
        this.serviceQty = 1
        lastServiceSelected.data.quantity = this.serviceQty
        this.popupServiceQty = true
      }
    },

    serviceDeselected (val) {
      if (val.data.type_service && val.data.type_service.toUpperCase() === 'AULA AVULSA') {
        this.serviceQty = 1
        val.label = `${val.data.name} x ${this.serviceQty}`
        val.data.quantity = this.serviceQty
      }
    },

    changeServiceQty () {
      const lastServiceSelected = this.credit.servicePackages[this.credit.servicePackages.length - 1]
      lastServiceSelected.data.quantity = this.serviceQty
      lastServiceSelected.label = `${lastServiceSelected.data.name} x ${this.serviceQty}`
      this.changeCreditSubTotal()
      this.popupServiceQty = false
    },

    closeChangeServiceQty () {
      this.serviceQty = 1
      this.popupServiceQty = false
    },
    changeCreditSubTotal () {
      if (this.credit.servicePackages.length) {
        this.credit.account_plan = this.credit.servicePackages[0].data.account_plan_id
      } else {
        this.credit.account_plan = null
      }
      const value = this.credit.servicePackages.reduce((a, v) => a + ((v.currencyValue * v.data.quantity) || 0), 0).toString()
      setValue(this.$refs.subTotal, value)
    },

    changeParcelsNumber () {
      this.credit.transactions = []
      if (parseInt(this.credit.parcelsNumber) === 1) {
        setValue(this.$refs.firstParcelValue, this.credit.total)
      } else {
        setValue(this.$refs.firstParcelValue, 0)
      }
    },

    recalculateTotal () {
      this.credit.transactions = [] //evita alterar valores e esquecer de gerar novamente as parcelas
      let discount = this.credit.discount_type === '%' ? this.credit.discount : (this.$refs.discount ? getValue(this.$refs.discount) : 0)
      const subTotal = getValue(this.$refs.subTotal)
      if (this.credit.discount_type === '%') {
        discount = ((discount / 100) * subTotal).toFixed(2)
      }
      if (subTotal >= 0) {
        this.credit.total = subTotal - discount
      }

      if (parseInt(this.credit.parcelsNumber) === 1) {
        setValue(this.$refs.firstParcelValue, this.credit.total)
      } else {
        setValue(this.$refs.firstParcelValue, 0)
      }
    },

    generateParcels () {
      this.disableGenerateParcels = true
      setTimeout(() => { this.disableGenerateParcels = false }, 2000) //TRAVA O BOTÃO POR 3 SEGUNDOS
      if (!this.validateCredit) {
        this.$vs.notify({
          time: 6000,
          title: 'AVISO',
          text: 'Por favor confira o preenchimento do formulário.',
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'warning'
        })
        return
      }
      this.credit.transactions = []

      const valueParcel = this.credit.total / parseInt(this.credit.parcelsNumber)
      let expirationDate = new Date(`${this.credit.expirationDateFirstParcel}T00:00:00`)

      for (let parcel = 1; parcel <= this.credit.parcelsNumber; parcel++) {
        if (parcel > 1) {
          if (this.credit.frequency === 'monthly') {
            expirationDate = new Date(`${this.credit.expirationDateFirstParcel}T00:00:00`).addMonths(parcel - 1)
          } else if (this.credit.frequency === 'biweekly') expirationDate.setDate(expirationDate.getDate() + 15)
          else if (this.credit.frequency === 'weekly') expirationDate.setDate(expirationDate.getDate() + 7)
        }
        this.credit.transactions.push({
          futureDate: false,
          type: 'CREDIT',
          // eslint-disable-next-line
          expiration_date: `${expirationDate.getFullYear()}-${('0' + (expirationDate.getMonth()+1)).slice(-2)}-${('0' + (expirationDate.getDate())).slice(-2)}`,
          payday: !(this.accountsFilteredOptions.find(o => o.card_account)) ? '' : this.today,
          value_pay: !(this.accountsFilteredOptions.find(o => o.card_account)) ? 0 : valueParcel,
          parcel,
          value_parcel: valueParcel,
          situation: !!(this.accountsFilteredOptions.find(o => o.card_account)), // false = PENDING | true = PAID
          value_total: this.credit.total,
          observation: this.credit.observation,
          account_plan: this.credit.account_plan,
          account: this.credit.account,
          type_payment_id: this.credit.type_payment_id,
          accountsFilteredOptions: this.accountsFilteredOptions
        })
      }

      //ESTE BLOCO SERVE APENAS PARA CASOS EM QUE A PRIMEIRA PARCELA É DEFINIDA ANTES DE GERAR AS PARCELAS
      //CASO NÃO HAJA UM VALOR INICIAL DE PRIMEIRA PARCELA COMENTAR O BLOCO É O SUFICIENTE
      const v = getValue(this.$refs.firstParcelValue)
      if (this.credit.parcelsNumber > 1 && v > 0) {
        this.credit.transactions[0].value_parcel = v
        this.recalculateParcelsValue(v)
      }


      // vou ajustar o valor da primeira parcela, colocando a diferenca
      this.$nextTick(() => {
        let differenceValue = 0
        this.credit.transactions.forEach(e => {
          differenceValue += e.value_parcel
        })
        this.credit.transactions[0].value_parcel = parseFloat((this.credit.transactions[0].value_parcel + (this.credit.total - differenceValue)).toFixed(2))
      })

    },

    recalculateParcelsValue (firstParcelValue) {
      if (this.credit.transactions.length) { //evita erro
        if (firstParcelValue > this.credit.total) {
          this.$vs.notify({
            time: 6000,
            title: 'AVISO',
            text: 'O valor digitado é maior que o valor total do contrato.',
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'warning'
          })

          this.credit.transactions[0].value_parcel = 0
          return
        }

        const valueParcels = (this.credit.total - firstParcelValue) / (parseInt(this.credit.parcelsNumber) - 1)
        for (let parcel = 1; parcel < (this.credit.parcelsNumber); parcel++) {
          this.credit.transactions[parcel].value_parcel = valueParcels
          if (this.credit.transactions[parcel].situation) {
            this.credit.transactions[parcel].value_pay = this.credit.transactions[parcel].value_parcel
          } else {
            this.credit.transactions[parcel].value_pay = 0
          }
        }
      }
    },
    // FILTRA AS CONTAS CONFORME O TIPO DE PAGAMENTO
    filterAccounts () {
      this.credit.account = null
      const myArrayFiltered = this.accounts.filter((account) => {
        return account.type_payments.some(e => e.id === this.credit.type_payment_id)
      })
      this.accountsFilteredOptions = myArrayFiltered
      if (myArrayFiltered.length) this.credit.account = myArrayFiltered[0].value
    },

    // FILTRA AS CONTAS NO FOR DAS PARCELAS CONFORME O TIPO DE PAGAMENTO
    filterAccountsParcels (i) {
      const parcel = this.credit.transactions[i]
      parcel.account = null
      const myArrayFiltered = this.accounts.filter((account) => {
        return account.type_payments.some(e => e.id === parcel.type_payment_id)
      })
      parcel.accountsFilteredOptions = myArrayFiltered
      if (myArrayFiltered.length) {
        parcel.account = myArrayFiltered[0].value
        if (parcel.accountsFilteredOptions.find(o => o.value === parcel.account) && parcel.accountsFilteredOptions.find(o => o.value === parcel.account).card_account) {
          parcel.situation = true
          parcel.payday = this.today
        } else {
          parcel.situation = false
          parcel.payday = ''
        }
      } else {
        parcel.situation = false
        parcel.payday = ''
      }
    },
    openLoading () {
      this.$vs.loading({
        container: '#boleto-loading',
        scale: 1
      })
    },

    storeCredit (pass = false) {
      /**
       * IMPORTANTE:
       * VERIFICAR SE TODAS AS PARCELAS QUE GERAM BOLETO USAM A MESMA CONTA
       */
      const typePaymentBoleto = this.typePayments.find(el => {
        return el.label.toLowerCase() === 'boleto'
      })
      if (typePaymentBoleto) {
        const parcelsBoleto = this.credit.transactions.filter(e => e.type_payment_id === typePaymentBoleto.value)
        const index = parcelsBoleto.findIndex(o => o.account !== parcelsBoleto[0].account)
        if (index !== -1) {
          this.$vs.notify({
            time: 6000,
            title: 'AVISO',
            text: 'TODAS as parcelas que emitem boleto devem utilizar a mesma conta!',
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'warning'
          })
          return
        }
      }
      /**
       * IMPORTANTE:
       * VERIFICAR SE TODAS AS PARCELAS QUE GERAM BOLETO USAM A MESMA CONTA
       */

      /**
       * CHECAR PAGAMENTO RETROATIVO
       */
      if (!pass) {
        const retroactiveParcels = this.credit.transactions.filter(e => {
          if (moment(e.payday).diff(moment(this.today), 'days') < 0) return true
        })

        if (retroactiveParcels.length) {
          this.popupPermissionPassword = true
          this.$vs.notify({
            time: 6000,
            title: 'AVISO',
            text: 'Há parcelas com baixa retroativa',
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'warning'
          })
          return
        }
      }

      // Loading
      this.$vs.loading()
      this.disableGenerateParcels = true
      setTimeout(() => { this.disableGenerateParcels = false }, 2000) //TRAVA O BOTÃO SALVAR

      // É PRECISO TRATAR PARA QUE O CONTRATO RECEBA CORRETAMENTE O CAMPO servicePackages
      const credit =  JSON.parse(JSON.stringify(this.credit))
      credit.servicePackages = credit.servicePackages.map(item => {
        item.data.services.map((s) => { delete s.pivot })
        return item.data
      })
      credit.sub_total = getValue(this.$refs.subTotal)
      if (credit.discount_type === '$') credit.discount = getValue(this.$refs.discount)

      this.$store.dispatch('transaction/store', credit)
        .then((resp) => {
          this.$emit('saved')
          this.localShow = false

          //VERIFICAR SE RETORNA URL DE BOLETO PARA MOSTRAR
          if (resp.data.boletoUrl) {
            this.boletoUrl = resp.data.boletoUrl
            this.remessaUrl = resp.data.remessaUrl
            this.popupShowBoleto = true
            this.openLoading()
            const self = this
            setTimeout(() => { self.$vs.loading.close('#boleto-loading > .con-vs-loading') }, 2000)
          }

          this.$vs.loading.close()
          this.$vs.notify({
            time: 5000,
            title: 'SUCESSO',
            text: 'Dados Salvos.',
            color: 'success',
            iconPack: 'feather',
            icon: 'icon-check'
          })
        })
        .catch(error => {
          this.$vs.loading.close()
          if (error.response.status === 412) {
            this.$vs.notify({
              time: 6000,
              title: 'AVISO',
              text: error.response.data.message,
              iconPack: 'feather',
              icon: 'icon-alert-circle',
              color: 'warning'
            })
          } else {
            this.$vs.notify({
              time: 5000,
              title: 'Erro',
              text: error.message,
              iconPack: 'feather',
              icon: 'icon-alert-circle',
              color: 'danger'
            })
          }
        })
    }
  },
  created () {
    if (!moduleTypePayment.isRegistered) {
      this.$store.registerModule('typePayment', moduleTypePayment)
      moduleTypePayment.isRegistered = true
    }
    if (!moduleAccount.isRegistered) {
      // this.$store.registerModule('account', moduleAccount)
      moduleAccount.isRegistered = true
    }
    if (!moduleServicePackage.isRegistered) {
      // this.$store.registerModule('servicePackage', moduleServicePackage)
      moduleServicePackage.isRegistered = true
    }
    if (!moduleAccountPlan.isRegistered) {
      // this.$store.registerModule('accountPlan', moduleAccountPlan)
      moduleAccountPlan.isRegistered = true
    }
    if (!moduleTransaction.isRegistered) {
      this.$store.registerModule('transaction', moduleTransaction)
      moduleTransaction.isRegistered = true
    }
  }
}
</script>

<style lang="scss">
.popup50 .vs-popup {
  width: 50% !important;
}
.popup80 .vs-popup {
  width: 80% !important;
}
.popup90 .vs-popup {
  width: 90% !important;
}
.noOverflow .vs-popup--content {
  overflow: hidden;
}
.vue_select_drop_size_180 .vs__dropdown-menu {
  max-height: 180px;
}
</style>
