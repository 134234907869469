var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "vs-popup",
        {
          staticClass: "popup90",
          attrs: {
            fullscreen: false,
            title: "Boleto(s)",
            active: _vm.popupShowBoleto,
          },
          on: {
            "update:active": function ($event) {
              _vm.popupShowBoleto = $event
            },
          },
        },
        [
          _c(
            "div",
            {
              staticClass: "vs-con-loading__container",
              attrs: { id: "boleto-loading" },
            },
            [
              _c("embed", {
                attrs: {
                  src: _vm.boletoUrl,
                  alt: "Boleto",
                  width: "100%",
                  height: "600",
                  type: "application/pdf",
                  pluginspage:
                    "http://www.adobe.com/products/acrobat/readstep2.html",
                },
              }),
              _c(
                "div",
                { staticClass: "vx-row" },
                [
                  _c("vs-divider"),
                  _c("div", { staticClass: "vx-col w-full mt-0" }, [
                    _c(
                      "div",
                      {
                        staticClass: "mt-2 flex flex-wrap items-center justify",
                      },
                      [
                        _c(
                          "vs-button",
                          {
                            staticClass: "w-full",
                            attrs: { href: _vm.remessaUrl },
                          },
                          [
                            _vm._v(
                              "!! Clique aqui para baixar o arquivo de remessa !!"
                            ),
                          ]
                        ),
                      ],
                      1
                    ),
                  ]),
                ],
                1
              ),
            ]
          ),
        ]
      ),
      _c(
        "vs-popup",
        {
          staticClass: "popup90",
          attrs: {
            fullscreen: false,
            title: "Cadastrar Receitas",
            active: _vm.localShow,
          },
          on: {
            "update:active": function ($event) {
              _vm.localShow = $event
            },
          },
        },
        [
          _c("permission-password", {
            staticStyle: { "z-index": "9999999999" },
            attrs: {
              show: _vm.popupPermissionPassword,
              title: "ALERTA DE BAIXA RETROATIVA",
              icon: "warning",
              actionButtonText: "Prosseguir",
            },
            on: {
              action: function ($event) {
                return _vm.storeCredit(true)
              },
              cancel: function ($event) {
                _vm.popupPermissionPassword = false
              },
            },
          }),
          _c("div", { staticClass: "vx-row" }, [
            _c(
              "div",
              { staticClass: "vx-col md:w-1/4 w-full mt-0" },
              [
                _c("label", { staticClass: "vs-input--label" }, [
                  _vm._v("Aluno"),
                ]),
                _c("v-select", {
                  directives: [
                    {
                      name: "validate",
                      rawName: "v-validate.initial",
                      value: "required",
                      expression: "'required'",
                      modifiers: { initial: true },
                    },
                  ],
                  attrs: {
                    clearable: false,
                    filterable: false,
                    reduce: (option) => option.value,
                    options: _vm.studentOptions,
                    "data-vv-as": "Aluno",
                    "data-vv-scope": "credit",
                    name: "student",
                    placeholder: "Digite CPF ou nome do aluno...",
                    dir: _vm.$vs.rtl ? "rtl" : "ltr",
                  },
                  on: { search: _vm.debouncedGetSearchStudent },
                  scopedSlots: _vm._u([
                    {
                      key: "no-options",
                      fn: function ({}) {
                        return [
                          _vm._v(
                            "\n            Nenhum resultado encontrado.\n          "
                          ),
                        ]
                      },
                    },
                  ]),
                  model: {
                    value: _vm.credit.student_id,
                    callback: function ($$v) {
                      _vm.$set(_vm.credit, "student_id", $$v)
                    },
                    expression: "credit.student_id",
                  },
                }),
                _c(
                  "span",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.errors.has("credit.student"),
                        expression: "errors.has('credit.student')",
                      },
                    ],
                    staticClass: "text-danger text-sm",
                  },
                  [_vm._v(_vm._s(_vm.errors.first("credit.student")))]
                ),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "vx-col md:w-1/4 w-full mt-0" },
              [
                _c("label", { staticClass: "vs-input--label" }, [
                  _vm._v("Serviços"),
                ]),
                _c("v-select", {
                  directives: [
                    {
                      name: "validate",
                      rawName: "v-validate.initial",
                      value: "required",
                      expression: "'required'",
                      modifiers: { initial: true },
                    },
                  ],
                  attrs: {
                    "data-vv-as": "Serviços",
                    "data-vv-scope": "credit",
                    name: "services",
                    options: _vm.servicePackages,
                    multiple: "",
                    placeholder: "Selecione",
                    dir: _vm.$vs.rtl ? "rtl" : "ltr",
                  },
                  on: { input: _vm.changeCreditSubTotal },
                  scopedSlots: _vm._u([
                    {
                      key: "no-options",
                      fn: function ({}) {
                        return [
                          _vm._v(
                            "\n            Nenhum resultado encontrado.\n          "
                          ),
                        ]
                      },
                    },
                  ]),
                  model: {
                    value: _vm.credit.servicePackages,
                    callback: function ($$v) {
                      _vm.$set(_vm.credit, "servicePackages", $$v)
                    },
                    expression: "credit.servicePackages",
                  },
                }),
                _c(
                  "span",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.errors.has("credit.services"),
                        expression: "errors.has('credit.services')",
                      },
                    ],
                    staticClass: "text-danger text-sm",
                  },
                  [_vm._v(_vm._s(_vm.errors.first("credit.services")))]
                ),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "vx-col md:w-1/6 w-full mt-0" },
              [
                _c("vs-input", {
                  directives: [
                    {
                      name: "currency",
                      rawName: "v-currency",
                      value: _vm.currencyConfig,
                      expression: "currencyConfig",
                    },
                  ],
                  ref: "subTotal",
                  staticClass: "w-full",
                  attrs: { label: "Valor" },
                  model: {
                    value: _vm.credit.sub_total,
                    callback: function ($$v) {
                      _vm.$set(_vm.credit, "sub_total", $$v)
                    },
                    expression: "credit.sub_total",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "vx-col md:w-1/6 w-full mt-0" },
              [
                _c("label", { staticClass: "vs-input--label" }, [
                  _vm._v("Desconto " + _vm._s(_vm.credit.discount_type)),
                ]),
                _c(
                  "vx-input-group",
                  {
                    attrs: {
                      prependClasses:
                        "border border-solid border-grey border-r-0",
                    },
                  },
                  [
                    _c("template", { slot: "append" }, [
                      _c(
                        "div",
                        { staticClass: "append-text btn-addon" },
                        [
                          _c(
                            "vs-button",
                            { on: { click: _vm.toggleDiscountType } },
                            [_vm._v(_vm._s(_vm.credit.discount_type))]
                          ),
                        ],
                        1
                      ),
                    ]),
                    _vm.credit.discount_type === "%"
                      ? _c("vs-input", {
                          key: "1__",
                          staticClass: "w-full",
                          attrs: { type: "number" },
                          model: {
                            value: _vm.credit.discount,
                            callback: function ($$v) {
                              _vm.$set(_vm.credit, "discount", $$v)
                            },
                            expression: "credit.discount",
                          },
                        })
                      : _c("vs-input", {
                          directives: [
                            {
                              name: "currency",
                              rawName: "v-currency",
                              value: _vm.currencyConfig,
                              expression: "currencyConfig",
                            },
                          ],
                          key: "2___",
                          ref: "discount",
                          staticClass: "w-full",
                          model: {
                            value: _vm.credit.discount,
                            callback: function ($$v) {
                              _vm.$set(_vm.credit, "discount", $$v)
                            },
                            expression: "credit.discount",
                          },
                        }),
                  ],
                  2
                ),
              ],
              1
            ),
            _c("div", { staticClass: "vx-col md:w-1/6 w-full mt-0" }, [
              _c("label", { staticClass: "vs-input--label" }, [
                _vm._v("Total"),
              ]),
              _c("h1", { staticClass: "flex flex-wrap justify-center" }, [
                _c("sup", { staticClass: "text-lg" }, [_vm._v("R$")]),
                _c("span", [
                  _vm._v(
                    _vm._s(
                      _vm.credit.total
                        .toLocaleString("pt-BR", {
                          style: "currency",
                          currency: "BRL",
                        })
                        .replace("R$", "")
                    )
                  ),
                ]),
              ]),
            ]),
          ]),
          _c("div", { staticClass: "vx-row" }, [
            _c(
              "div",
              { staticClass: "vx-col md:w-1/5 w-full mt-2" },
              [
                _c("label", { staticClass: "vs-input--label" }, [
                  _vm._v("Nº de Parcelas"),
                ]),
                _c("vs-input-number", {
                  directives: [
                    {
                      name: "validate",
                      rawName: "v-validate",
                      value: "required|min_value:1",
                      expression: "'required|min_value:1'",
                    },
                  ],
                  staticClass: "mt-2",
                  attrs: {
                    min: "1",
                    "data-vv-as": "Parcelas",
                    "data-vv-scope": "credit",
                    name: "parcelsNumber",
                  },
                  on: { input: _vm.changeParcelsNumber },
                  model: {
                    value: _vm.credit.parcelsNumber,
                    callback: function ($$v) {
                      _vm.$set(_vm.credit, "parcelsNumber", $$v)
                    },
                    expression: "credit.parcelsNumber",
                  },
                }),
                _c(
                  "span",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.errors.has("credit.parcelsNumber"),
                        expression: "errors.has('credit.parcelsNumber')",
                      },
                    ],
                    staticClass: "text-danger text-sm",
                  },
                  [_vm._v(_vm._s(_vm.errors.first("credit.parcelsNumber")))]
                ),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "vx-col md:w-1/5 w-full mt-2" },
              [
                _c("vs-input", {
                  directives: [
                    {
                      name: "currency",
                      rawName: "v-currency",
                      value: _vm.currencyConfig,
                      expression: "currencyConfig",
                    },
                  ],
                  ref: "firstParcelValue",
                  staticClass: "w-full mt-0",
                  attrs: {
                    label: "Valor 1ª Parcela",
                    disabled: _vm.credit.parcelsNumber < 2,
                  },
                  model: {
                    value: _vm.credit.firstParcelValue,
                    callback: function ($$v) {
                      _vm.$set(_vm.credit, "firstParcelValue", $$v)
                    },
                    expression: "credit.firstParcelValue",
                  },
                }),
                _vm.errorfirstParcelValue
                  ? _c("span", { staticClass: "text-danger text-sm" }, [
                      _vm._v("Este valor é maior que o total."),
                    ])
                  : _vm._e(),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "vx-col md:w-1/5 w-full mt-2" },
              [
                _c("vs-input", {
                  directives: [
                    {
                      name: "validate",
                      rawName: "v-validate.initial",
                      value: "required",
                      expression: "'required'",
                      modifiers: { initial: true },
                    },
                  ],
                  staticClass: "w-full",
                  attrs: {
                    type: "date",
                    label: "1º Vencimento",
                    "data-vv-as": "1º Vencimento",
                    "data-vv-scope": "credit",
                    name: "expirationDateFirstParcel",
                  },
                  model: {
                    value: _vm.credit.expirationDateFirstParcel,
                    callback: function ($$v) {
                      _vm.$set(_vm.credit, "expirationDateFirstParcel", $$v)
                    },
                    expression: "credit.expirationDateFirstParcel",
                  },
                }),
                _c(
                  "span",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.errors.has(
                          "credit.expirationDateFirstParcel"
                        ),
                        expression:
                          "errors.has('credit.expirationDateFirstParcel')",
                      },
                    ],
                    staticClass: "text-danger text-sm",
                  },
                  [_vm._v("Este campo é obrigatório.")]
                ),
                !_vm.isSameOrAfterToday(_vm.credit.expirationDateFirstParcel)
                  ? _c("span", { staticClass: "text-danger text-sm" }, [
                      _vm._v("A primeira parcela já está vencida."),
                    ])
                  : _vm._e(),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "vx-col md:w-1/5 w-full mt-2" },
              [
                _c("label", { staticClass: "vs-input--label" }, [
                  _vm._v("Periodicidade"),
                ]),
                _c("v-select", {
                  attrs: {
                    reduce: (option) => option.value,
                    clearable: false,
                    options: [
                      { label: "Mensal", value: "monthly" },
                      { label: "Quinzenal", value: "biweekly" },
                      { label: "Semanal", value: "weekly" },
                    ],
                    placeholder: "Selecione",
                    dir: _vm.$vs.rtl ? "rtl" : "ltr",
                  },
                  model: {
                    value: _vm.credit.frequency,
                    callback: function ($$v) {
                      _vm.$set(_vm.credit, "frequency", $$v)
                    },
                    expression: "credit.frequency",
                  },
                }),
              ],
              1
            ),
            _c("div", { staticClass: "vx-col md:w-1/5 w-full mt-2" }, [
              _c("label", { staticClass: "vs-input--label" }, [
                _vm._v("Aplicar Juros"),
              ]),
              _c(
                "div",
                { staticClass: "flex flex-wrap items-center justify-center" },
                [
                  _c(
                    "vs-switch",
                    {
                      staticClass: "w-1/2 mt-2",
                      attrs: {
                        "icon-pack": "feather",
                        "vs-icon-on": "icon-check-circle",
                        "vs-icon-off": "icon-slash",
                      },
                      model: {
                        value: _vm.credit.applyInterest,
                        callback: function ($$v) {
                          _vm.$set(_vm.credit, "applyInterest", $$v)
                        },
                        expression: "credit.applyInterest",
                      },
                    },
                    [
                      _c("span", { attrs: { slot: "on" }, slot: "on" }, [
                        _vm._v("SIM"),
                      ]),
                      _c("span", { attrs: { slot: "off" }, slot: "off" }, [
                        _vm._v("NÃO"),
                      ]),
                    ]
                  ),
                ],
                1
              ),
            ]),
          ]),
          _c("div", { staticClass: "vx-row" }, [
            _c(
              "div",
              { staticClass: "vx-col md:w-1/5 w-full mt-2" },
              [
                _c("label", { staticClass: "vs-input--label" }, [
                  _vm._v("Tipo de Pagamento"),
                ]),
                _c("v-select", {
                  directives: [
                    {
                      name: "validate",
                      rawName: "v-validate.initial",
                      value: "required",
                      expression: "'required'",
                      modifiers: { initial: true },
                    },
                  ],
                  staticClass: "vue_select_drop_size_180",
                  attrs: {
                    reduce: (option) => option.value,
                    clearable: false,
                    "data-vv-as": "Tipo de Pagamento",
                    "data-vv-scope": "credit",
                    name: "type_payment_id",
                    options: _vm.typePayments,
                    placeholder: "Selecione",
                    dir: _vm.$vs.rtl ? "rtl" : "ltr",
                  },
                  on: { input: _vm.filterAccounts },
                  scopedSlots: _vm._u([
                    {
                      key: "no-options",
                      fn: function ({}) {
                        return [
                          _vm._v(
                            "\n            Desculpe, nenhum resultado encontrado.\n          "
                          ),
                        ]
                      },
                    },
                  ]),
                  model: {
                    value: _vm.credit.type_payment_id,
                    callback: function ($$v) {
                      _vm.$set(_vm.credit, "type_payment_id", $$v)
                    },
                    expression: "credit.type_payment_id",
                  },
                }),
                _c(
                  "span",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.errors.has("credit.type_payment_id"),
                        expression: "errors.has('credit.type_payment_id')",
                      },
                    ],
                    staticClass: "text-danger text-sm",
                  },
                  [_vm._v("Este campo é obrigatório.")]
                ),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "vx-col md:w-1/5 w-full mt-2" },
              [
                _c("label", { staticClass: "vs-input--label" }, [
                  _vm._v("Conta"),
                ]),
                _c("v-select", {
                  directives: [
                    {
                      name: "validate",
                      rawName: "v-validate.initial",
                      value: "required",
                      expression: "'required'",
                      modifiers: { initial: true },
                    },
                  ],
                  staticClass: "vue_select_drop_size_180",
                  attrs: {
                    disabled: !_vm.credit.type_payment_id,
                    reduce: (option) => option.value,
                    clearable: false,
                    "data-vv-as": "Conta",
                    "data-vv-scope": "credit",
                    name: "account",
                    options: _vm.accountsFilteredOptions,
                    placeholder: "Selecione",
                    dir: _vm.$vs.rtl ? "rtl" : "ltr",
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "no-options",
                      fn: function ({}) {
                        return [
                          _vm._v(
                            "\n            Desculpe, nenhum resultado encontrado.\n          "
                          ),
                        ]
                      },
                    },
                  ]),
                  model: {
                    value: _vm.credit.account,
                    callback: function ($$v) {
                      _vm.$set(_vm.credit, "account", $$v)
                    },
                    expression: "credit.account",
                  },
                }),
                _c(
                  "span",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.errors.has("credit.account"),
                        expression: "errors.has('credit.account')",
                      },
                    ],
                    staticClass: "text-danger text-sm",
                  },
                  [_vm._v("Este campo é obrigatório.")]
                ),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "vx-col md:w-1/5 w-full mt-2" },
              [
                _c("label", { staticClass: "vs-input--label" }, [
                  _vm._v("Plano de contas"),
                ]),
                _c("v-select", {
                  staticClass: "vue_select_drop_size_180",
                  attrs: {
                    reduce: (option) => option.value,
                    options: _vm.accountPlans,
                    placeholder: "Selecione",
                    dir: _vm.$vs.rtl ? "rtl" : "ltr",
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "no-options",
                      fn: function ({}) {
                        return [
                          _vm._v(
                            "\n            Desculpe, nenhum resultado encontrado.\n          "
                          ),
                        ]
                      },
                    },
                  ]),
                  model: {
                    value: _vm.credit.account_plan,
                    callback: function ($$v) {
                      _vm.$set(_vm.credit, "account_plan", $$v)
                    },
                    expression: "credit.account_plan",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "vx-col md:w-2/5 w-full mt-2" },
              [
                _c("vs-input", {
                  staticClass: "w-full",
                  attrs: { label: "Descrição" },
                  model: {
                    value: _vm.credit.observation,
                    callback: function ($$v) {
                      _vm.$set(_vm.credit, "observation", $$v)
                    },
                    expression: "credit.observation",
                  },
                }),
              ],
              1
            ),
          ]),
          _c("div", { staticClass: "vx-row" }, [
            _c("div", { staticClass: "vx-col w-full mt-2" }, [
              _c(
                "div",
                { staticClass: "mt-0 flex flex-wrap items-center justify-end" },
                [
                  _c(
                    "vs-button",
                    {
                      staticClass: "w-1/5",
                      attrs: {
                        disabled: _vm.disableGenerateParcels,
                        type: "border",
                        color: "warning",
                        "icon-pack": "feather",
                        icon: "icon-dollar-sign",
                      },
                      on: { click: _vm.generateParcels },
                    },
                    [_vm._v("Gerar Parcelas")]
                  ),
                ],
                1
              ),
            ]),
          ]),
          _c(
            "vs-divider",
            { attrs: { position: "left" } },
            [
              _c("feather-icon", {
                staticClass: "mr-0",
                attrs: { icon: "DollarSignIcon", svgClasses: "w-4 h-4" },
              }),
              _c("span", { staticClass: "mb-4" }, [_vm._v("Parcelas")]),
            ],
            1
          ),
          _vm.credit.transactions.length
            ? _c(
                "vs-table",
                {
                  staticStyle: { overflow: "-webkit-paged-y" },
                  attrs: { data: _vm.credit.transactions, hoverFlat: "" },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "default",
                        fn: function ({ data }) {
                          return [
                            _vm._l(data, function (parcel, i) {
                              return _c(
                                "vs-tr",
                                {
                                  key: i,
                                  staticStyle: { opacity: "1 !important" },
                                },
                                [
                                  _c("vs-td", [
                                    _vm._v(" " + _vm._s(i + 1) + " "),
                                  ]),
                                  _c(
                                    "vs-td",
                                    [
                                      _c("currency-input", {
                                        staticClass:
                                          "w-32 vs-inputx vs-input--input normal hasValue",
                                        attrs: {
                                          currency: { prefix: "R$ " },
                                          valueAsInteger: false,
                                          distractionFree: false,
                                          precision: 2,
                                          autoDecimalMode: true,
                                          valueRange: { min: 0 },
                                          allowNegative: false,
                                        },
                                        on: {
                                          input: function ($event) {
                                            parcel.situation
                                              ? (parcel.value_pay =
                                                  parcel.value_parcel)
                                              : (parcel.value_pay = 0)
                                          },
                                        },
                                        model: {
                                          value: parcel.value_parcel,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              parcel,
                                              "value_parcel",
                                              $$v
                                            )
                                          },
                                          expression: "parcel.value_parcel",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "vs-td",
                                    [
                                      _c("vs-input", {
                                        directives: [
                                          {
                                            name: "validate",
                                            rawName: "v-validate.initial",
                                            value: { required: true },
                                            expression: "{ required: true }",
                                            modifiers: { initial: true },
                                          },
                                        ],
                                        staticClass: "w-48",
                                        attrs: {
                                          type: "date",
                                          "data-vv-as": "Vencimento",
                                          "data-vv-scope": "credit",
                                          name: "expiration_date" + i,
                                        },
                                        model: {
                                          value: parcel.expiration_date,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              parcel,
                                              "expiration_date",
                                              $$v
                                            )
                                          },
                                          expression: "parcel.expiration_date",
                                        },
                                      }),
                                      _c(
                                        "span",
                                        {
                                          directives: [
                                            {
                                              name: "show",
                                              rawName: "v-show",
                                              value: _vm.errors.has(
                                                "credit.expiration_date" + i
                                              ),
                                              expression:
                                                "errors.has('credit.expiration_date'+i)",
                                            },
                                          ],
                                          staticClass: "text-danger text-sm",
                                        },
                                        [_vm._v("Campo obrigatório")]
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "vs-td",
                                    [
                                      _c("v-select", {
                                        staticClass:
                                          "vue_select_drop_size_100 w-48",
                                        attrs: {
                                          clearable: false,
                                          reduce: (option) => option.value,
                                          options: _vm.typePayments,
                                          placeholder: "Selecione",
                                          dir: _vm.$vs.rtl ? "rtl" : "ltr",
                                        },
                                        on: {
                                          input: function ($event) {
                                            return _vm.filterAccountsParcels(i)
                                          },
                                        },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "no-options",
                                              fn: function ({}) {
                                                return [
                                                  _vm._v(
                                                    "\n                Nenhum resultado encontrado.\n              "
                                                  ),
                                                ]
                                              },
                                            },
                                          ],
                                          null,
                                          true
                                        ),
                                        model: {
                                          value: parcel.type_payment_id,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              parcel,
                                              "type_payment_id",
                                              $$v
                                            )
                                          },
                                          expression: "parcel.type_payment_id",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "vs-td",
                                    [
                                      _c("v-select", {
                                        directives: [
                                          {
                                            name: "validate",
                                            rawName: "v-validate.initial",
                                            value: {
                                              required: !parcel.account,
                                            },
                                            expression:
                                              "{ required: !parcel.account }",
                                            modifiers: { initial: true },
                                          },
                                        ],
                                        staticClass:
                                          "vue_select_drop_size_100 w-48",
                                        attrs: {
                                          clearable: false,
                                          disabled: !parcel.type_payment_id,
                                          reduce: (option) => option.value,
                                          options:
                                            parcel.accountsFilteredOptions,
                                          placeholder: "Selecione",
                                          dir: _vm.$vs.rtl ? "rtl" : "ltr",
                                          "data-vv-as": "Conta",
                                          "data-vv-scope": "credit",
                                          name: "account" + i,
                                        },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "no-options",
                                              fn: function ({}) {
                                                return [
                                                  _vm._v(
                                                    "\n                Nenhum resultado encontrado.\n              "
                                                  ),
                                                ]
                                              },
                                            },
                                          ],
                                          null,
                                          true
                                        ),
                                        model: {
                                          value: parcel.account,
                                          callback: function ($$v) {
                                            _vm.$set(parcel, "account", $$v)
                                          },
                                          expression: "parcel.account",
                                        },
                                      }),
                                      _c(
                                        "span",
                                        {
                                          directives: [
                                            {
                                              name: "show",
                                              rawName: "v-show",
                                              value: _vm.errors.has(
                                                "credit.account" + i
                                              ),
                                              expression:
                                                "errors.has('credit.account'+i)",
                                            },
                                          ],
                                          staticClass: "text-danger text-sm",
                                        },
                                        [_vm._v("Campo obrigatório")]
                                      ),
                                    ],
                                    1
                                  ),
                                  _c("vs-td", [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "flex flex-wrap items-center justify-center",
                                      },
                                      [
                                        _c(
                                          "vs-switch",
                                          {
                                            staticClass: "w-28 mt-2",
                                            attrs: {
                                              "icon-pack": "feather",
                                              "vs-icon-on": "icon-check-circle",
                                              "vs-icon-off": "icon-slash",
                                            },
                                            on: {
                                              input: function ($event) {
                                                parcel.situation
                                                  ? (parcel.value_pay =
                                                      parcel.value_parcel)
                                                  : (parcel.value_pay = 0)
                                                parcel.situation
                                                  ? (parcel.payday = _vm.today)
                                                  : (parcel.payday = "")
                                                parcel.situation
                                                  ? ""
                                                  : (parcel.futureDate = false)
                                                _vm.checkPayday(parcel)
                                              },
                                            },
                                            model: {
                                              value: parcel.situation,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  parcel,
                                                  "situation",
                                                  $$v
                                                )
                                              },
                                              expression: "parcel.situation",
                                            },
                                          },
                                          [
                                            _c(
                                              "span",
                                              {
                                                attrs: { slot: "off" },
                                                slot: "off",
                                              },
                                              [_vm._v("ABERTA")]
                                            ),
                                            _c(
                                              "span",
                                              {
                                                attrs: { slot: "on" },
                                                slot: "on",
                                              },
                                              [_vm._v("QUITADA")]
                                            ),
                                          ]
                                        ),
                                      ],
                                      1
                                    ),
                                  ]),
                                  _c(
                                    "vs-td",
                                    [
                                      _c("vs-input", {
                                        directives: [
                                          {
                                            name: "validate",
                                            rawName: "v-validate.initial",
                                            value: {
                                              required: parcel.situation,
                                            },
                                            expression:
                                              "{ required: parcel.situation }",
                                            modifiers: { initial: true },
                                          },
                                        ],
                                        staticClass: "w-48",
                                        attrs: {
                                          type: "date",
                                          disabled: !parcel.situation,
                                          "data-vv-as": "Pago em",
                                          "data-vv-scope": "credit",
                                          name: "payday" + i,
                                        },
                                        on: {
                                          change: function ($event) {
                                            return _vm.checkPayday(parcel)
                                          },
                                        },
                                        model: {
                                          value: parcel.payday,
                                          callback: function ($$v) {
                                            _vm.$set(parcel, "payday", $$v)
                                          },
                                          expression: "parcel.payday",
                                        },
                                      }),
                                      _c(
                                        "span",
                                        {
                                          directives: [
                                            {
                                              name: "show",
                                              rawName: "v-show",
                                              value: _vm.errors.has(
                                                "credit.payday" + i
                                              ),
                                              expression:
                                                "errors.has('credit.payday'+i)",
                                            },
                                          ],
                                          staticClass: "text-danger text-sm",
                                        },
                                        [_vm._v("Campo obrigatório")]
                                      ),
                                      _c(
                                        "span",
                                        {
                                          directives: [
                                            {
                                              name: "show",
                                              rawName: "v-show",
                                              value: parcel.futureDate,
                                              expression: "parcel.futureDate",
                                            },
                                          ],
                                          staticClass: "text-danger text-sm",
                                        },
                                        [_vm._v("Data futura não permitida")]
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              )
                            }),
                            _c(
                              "vs-tr",
                              { staticStyle: { opacity: "1 !important" } },
                              [
                                _c(
                                  "vs-td",
                                  { attrs: { colspan: "8" } },
                                  [
                                    _c("vs-divider"),
                                    _c(
                                      "div",
                                      { staticClass: "vx-col w-full" },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "mt-2 flex flex-wrap items-center",
                                          },
                                          [
                                            _c(
                                              "vs-button",
                                              {
                                                staticClass: "mr-4 mb-2",
                                                attrs: { color: "secondary" },
                                                on: {
                                                  click: function ($event) {
                                                    _vm.localShow = false
                                                  },
                                                },
                                              },
                                              [_vm._v("Cancelar")]
                                            ),
                                            _c(
                                              "vs-button",
                                              {
                                                staticClass: "mr-auto mb-2",
                                                attrs: {
                                                  disabled:
                                                    !_vm.validateCredit ||
                                                    _vm.disableGenerateParcels ||
                                                    _vm.disableSave,
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.storeCredit()
                                                  },
                                                },
                                              },
                                              [_vm._v("Salvar")]
                                            ),
                                          ],
                                          1
                                        ),
                                      ]
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ]
                        },
                      },
                    ],
                    null,
                    false,
                    1166310808
                  ),
                },
                [
                  _c(
                    "template",
                    { slot: "thead" },
                    [
                      _c("vs-th", [_vm._v("#")]),
                      _c("vs-th", [_vm._v("Valor")]),
                      _c("vs-th", [_vm._v("Vencimento")]),
                      _c("vs-th", [_vm._v("Forma Pagamento")]),
                      _c("vs-th", [_vm._v("Conta")]),
                      _c("vs-th", [_vm._v("Situação")]),
                      _c("vs-th", [_vm._v("Pago Em")]),
                    ],
                    1
                  ),
                ],
                2
              )
            : _vm._e(),
          !_vm.credit.transactions.length
            ? _c(
                "vs-alert",
                {
                  staticClass: "mt-4 mb-4",
                  staticStyle: { height: "auto" },
                  attrs: {
                    active: true,
                    color: "warning",
                    "icon-pack": "feather",
                    icon: "icon-info",
                  },
                },
                [
                  _c("span", [
                    _vm._v(
                      "Por favor preencha o formulário e gere as parcelas para poder cadastrar!"
                    ),
                  ]),
                ]
              )
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }